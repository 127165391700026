import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { delete_cookie, bake_cookie } from "../read_cookie";
import axios from "axios";
import config from "../configs/config";

const Redirect = () => {
  let params = useParams();
  let navigate = useNavigate();
  
  let token = null, profileId = null, ProgramCode = null;
  let fullEncStr = params.id;
  const URL = config.qa.node_url;

  const sendLogToServer = async (logData) => {
    try {
      await axios.post(`${URL}save-log`, logData);
      console.log("log sent successfull");
    } catch (error) {
      console.error("log error:", error);
    }
  };

  try {
    delete_cookie("pnc-rewards-token");
    delete_cookie("profileId");
    delete_cookie("ProgramCode");

    if (fullEncStr && fullEncStr !== "0") {
      let encArr = fullEncStr.split("&&");

      if (encArr.length >= 3) {
        token = encArr[0].toString();
        profileId = encArr[1].toString();
        ProgramCode = encArr[2].toString();

        bake_cookie("pnc-rewards-token", token);
        bake_cookie("profileId", profileId);
        bake_cookie("ProgramCode", ProgramCode);
      } else {
        throw new Error("Format Invalid fullEncStr");
      }
    }
  } catch (error) {
    sendLogToServer({
      token,
      profileId,
      ProgramCode,
      errorLog: `Cookie Handle ERROR: ${error.message}`,
    });
  }

  useEffect(() => {
    sendLogToServer({token, profileId, ProgramCode, errorLog: 'NO ERROR - SUCESS LOG' });
    navigate(`/`);
  }, [navigate]);

  return (
    <section className="pnc-help-section">
      <div className="loader"></div>
    </section>
  );
};

export default Redirect;
