import React from "react";
import { StoreProvider } from "easy-peasy";
import Store from "./service/store";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
import MyProfile from "./components/myProfile/myProfile";
import MyCommunication from "./components/myCommunication/myCommunication";
import MyAccount from "./components/myAccount/myAccount";
import MessageDetails from "./components/messageDetails/messageDetails";
import Transaction from "./components/transaction/transaction";
import OrderHistory from "./components/orderHistory/orderHistory";
import RewardMessages from "./components/rewardMessage/rewardMessages";
import Resp from "./components/Redirect";
import SAML from "./components/bakktSaml";
import Logout from "./components/logout/logout";
import Faq from "./components/faq/faq";
import Confirmation from "./components/confirmation/confirmation";
import Notfound from "./components/notfound";
import Auth from "./components/auth";
import CLickBack from "./components/confirmation/clickback";
import ErrorPage from "./components/errorPage";
import MaintenancePage from "./components/maintenancePage";
import Benefits from "./components/benefits/benefits";
import OldHome from "./components/oldHome/oldHome"; //this is jusf for PNC to test home

const PrivateRoute = ({ children }) => {
  return Auth.getAuth() ? children : <Navigate to="/" />;
};

Auth.authenticate();

function App() {
  let catalogUrl;
  if (process.env.REACT_APP_ENV === "production") {
    catalogUrl = process.env.REACT_APP_CATALOG_URL;
  } else {
    catalogUrl = process.env.REACT_APP_CATALOG_URL_QA;
  }
  global.catalogUrl = catalogUrl;

  return (
    <React.Fragment>
      <StoreProvider store={Store}>
        <Router>
          <Header />
          <section className="pnc-wrapper">
            <Routes>
              {process.env.REACT_APP_MAINTANENCE === "true" ? (
                <Route
                  path="*"
                  element={<MaintenancePage title="PNC Cash Unlimited | MaintenancePage" />}
                />
              ) : (
                <>
                  <Route path="/home" element={<Home title="PNC Cash Unlimited | Home" />} />
                  <Route path="/" element={<Home title="PNC Cash Unlimited | Home" />} />
                  <Route
                    path="/contact"
                    element={<Contact title="PNC Cash Unlimited | Contact Us" />}
                  />
                  <Route
                    path="/Help/ContactUs"
                    element={<Contact title="PNC Cash Unlimited | Contact Us" />}
                  />
                  <Route
                    path="/MyProfile/Communications"
                    element={
                      <PrivateRoute>
                        <MyCommunication title="PNC Cash Unlimited | My Communications" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/RewardsOverview"
                    element={
                      <PrivateRoute>
                        <MyAccount title="PNC Cash Unlimited | Rewards Overview" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Messaging/messageDetails/:type/:id"
                    element={
                      <PrivateRoute>
                        <MessageDetails title="PNC Cash Unlimited | Message Details" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/Transactions"
                    element={
                      <PrivateRoute>
                        <Transaction title="PNC Cash Unlimited | Transactions" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/OrderHistory"
                    element={
                      <PrivateRoute>
                        <OrderHistory title="PNC Cash Unlimited | Order History" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/Benefits"
                    element={
                      <PrivateRoute>
                        <Benefits title="PNC Cash Unlimited | Benefits" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Profile"
                    element={
                      <PrivateRoute>
                        <MyProfile title="PNC Cash Unlimited | My Profile" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Messaging"
                    element={
                      <PrivateRoute>
                        <RewardMessages title="PNC Cash Unlimited | Messages" />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                    path="/MyProfile/LinkedCards"
                    element={
                      <PrivateRoute>
                        <LinkedCards title="PNC Cash Unlimited | Linked Cards" />
                      </PrivateRoute>
                    }
                  /> */}
                  <Route path="/Help/FAQs" element={<Faq title="PNC Cash Unlimited | FAQ" />} />
                  <Route path="/SAML/b2rLogin" element={<SAML />} />
                  {/* <Route path="/FrontPorch" element={<FrontPorch />} /> */}
                  <Route
                    path="/confirmation"
                    element={
                      <Confirmation title="PNC Cash Unlimited | Email Address Confirmation" />
                    }
                  />
                  <Route
                    path="/clickback/confirmation/"
                    element={<CLickBack title="PNC Cash Unlimited | Email Address Confirmation" />}
                  />
                  <Route path="/resp/:id" element={<Resp />} />
                  <Route path="/Logout/Logout" element={<Logout />} />
                  <Route
                    path="/errorPage"
                    element={<ErrorPage title="PNC Cash Unlimited | Error" />}
                  />
                  <Route
                    path="*"
                    element={<Notfound title="PNC Cash Unlimited | System Error" />}
                  />
                </>
              )}
            </Routes>
          </section>
          <Footer />
        </Router>
      </StoreProvider>
    </React.Fragment>
  );
}

export default App;
